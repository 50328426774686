(function($, MediaElementPlayer, window, document) {
  var Overlays = (function() {
    var $mainNav = $('.main-nav');
    var $layout = $('#layout');

    var overlays = [
      { $link: $('#main-nav__phone'), $el: $('#overlay-phone') },
      { $link: $('#main-nav__locations'), $el: $('#overlay-locations') },
      { $link: $('#main-nav__contact'), $el: $('#overlay-contact') },
      { $link: $('#main-nav__menu'), $el: $('#overlay-menu') },
      { $link: $('.overlay-blog-tags-open'), $el: $('#overlay-blog_tags') },
      { $link: $('.overlay-blog-archive-open'), $el: $('#overlay-blog_archive') },
      { $link: $('.overlay-social-open'), $el: $('#overlay-social') },
      { $link: $('.overlay-ppc'), $el: $('#overlay-ppc_cta') },
      { $link: $('.overlay-video-open'), $el: $('#overlay-video')}
    ];

    var _menuOverlayArrows = function() {
      var menuOverlay = document.querySelector('#overlay-menu');

      menuOverlay.addEventListener('click', function(e) {
        var pageGroupLink = e.target.classList.contains('page-group-link');

        if (pageGroupLink) {
          e.preventDefault();
          var pageGroup = e.target.parentNode;
          var pageGroupList = pageGroup.querySelector('ul:first-of-type');
          var arrowIcon = pageGroup.querySelector('.circled-arrow-icon');

          pageGroupList.classList.toggle('hide');

          arrowIcon.style.transform = pageGroupList.classList.contains('hide')
            ? 'rotate(90deg)' : 'rotate(-90deg)';
        }
      });
    };

    var _addPushState = function() {
      var scrollTop = $('body').scrollTop();
      history.replaceState({ scrollTop: scrollTop }, null, null);
      history.pushState({ overlay: 'open' }, null, null);
    };

    var _overlayIsOpen = function() {
      return $('.overlay').hasClass('active');
    };

    var _toggleClass = function($el, className) {
      if ($el.hasClass(className)) {
        $el.removeClass(className);
      } else {
        $el.addClass(className);
      }
    };

    var _handleKeyUp = function(e) {
      e.preventDefault();
      if (e.keyCode == 27) {
        window.history.back();
      }
    };

    var _initPageOverlayCloser = function($el, videoPlayer) {
      window.onpopstate = function(e) {
        if (!_overlayIsOpen()) {
          return;
        }

        var scrollTop = e.state.scrollTop;

        _deactivate($el, videoPlayer);
        if (scrollTop) {
          $('body').scrollTop(scrollTop);
        }
      };
    };

    var _activate = function($el, videoPlayer) {

      $(document).keyup(function(e) {
        _handleKeyUp(e);
      });
      _toggleClass($el, 'active');
      _toggleClass($layout, 'overlay-active');
      _toggleClass($mainNav, 'active');
      _addPushState();
      $.scrollLock();
      _initPageOverlayCloser($el, videoPlayer);
    };

    var _deactivate = function($el, videoPlayer) {
      if ($el.is('#overlay-video')) {
        videoPlayer.pause();
      }
      $(document).off('keyup');
      _toggleClass($el, 'active');
      _toggleClass($layout, 'overlay-active');
      _toggleClass($mainNav, 'active');
      $.scrollLock();
    };

    var _bindEvents = function() {
      // Clicking close goes back
      $('.overlay__close').on('click', function() {
        window.history.back();
      });
      // Activate each overlay link
      overlays.forEach(function(overlay) {
        overlay.$link.on('click', function() {
          if (overlay.$el.is('#overlay-video')) {
            const $video = overlay.$el.find('video');

            if (navigator.userAgent.match(/iPhone|iPod|iPad/i)) {

              const video = $video[0];
              video.play();
              if (video.requestFullscreen) {
                video.requestFullscreen();
              } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
              } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
              } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
              }

            } else {

              const $replayButton = overlay.$el.find('.overlay-video__replay-button');
              $video.attr('playsinline', '');
              const videoPlayer = new MediaElementPlayer($video, {
                type: $video.data('type'),
                mode: 'auto',
                startVolume: 0.8,
                AndroidUseNativeControls: true,
                iPhoneUseNativeControls: true,
                iPadUseNativeControls: true,
                pluginPath: '//d4xmq39929kw8.cloudfront.net/mediaelement-2.14/',
                plugins: ['flash'],
                flashName: 'flashmediaelement-cdn.swf',
                success(mediaElement) {
                  // success listeners won't work without event object passed in?
                  mediaElement.addEventListener('pause', function(e) { // eslint-disable-line
                    overlay.$el.removeClass('playing');
                  });
                  mediaElement.addEventListener('play', function(e) { // eslint-disable-line
                    overlay.$el.removeClass('ended');
                    overlay.$el.addClass('playing');
                  });
                  mediaElement.addEventListener('ended', function(e) { // eslint-disable-line
                    overlay.$el.addClass('ended');
                  });
                },
              });
              _activate(overlay.$el, videoPlayer);
              $replayButton[0].addEventListener('click', function() {
                videoPlayer.play();
              });
              videoPlayer.play();

            }
          } else {
            _activate(overlay.$el);
            if (overlay.$link.attr('id') === 'main-nav__contact') {
              // initValidation('is-form.overlay-form'); // eslint-disable-line

              /** DUPLICATE CODE FROM THESIS **/
              var $form = $('#overlay-contact form.is-form');

              if (!$.isFunction(jQuery.fn.expanding)) {
                console.log('expanding-textareas is required'); // eslint-disable-line
                return true;
              }

              if (!$form.length || !$form.is(':visible')) {
                return true;
              }

              $form.find('textarea').each(function () {
                var $textarea = $(this);

                if ($textarea.parent().hasClass('expanding-wrapper')) {
                  return true;
                }

                $textarea.expanding();
              });
              /** **/
            }
          }
        });
      });
      _menuOverlayArrows();
    };

    return {
      init: function() {
        _bindEvents();

        $('#overlay-menu .page-group').each(function(i, el) {
          if (!el.classList.contains('active')) {
            $(el).find('.page-group-link + ul').addClass('hide');
          }
        });
      }
    };
  })();

  document.addEventListener('DOMContentLoaded', function() {
    window.Palette.Overlays = Overlays;
    Palette.Overlays.init();
  });
})(jQuery, MediaElementPlayer, window, document);
