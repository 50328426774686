(function($, window, document) {
  var Gallery = (function() {
    var init = function(selector, options) {
      var $elements = $(selector);
      if ($elements.length > 0) {
        $elements.slick(options);
      }
    };

    return {
      init: init
    };
  })();

  document.addEventListener('DOMContentLoaded', function() {
    window.Palette.Gallery = Gallery;
    var options = {
      dots: true,
      arrows: true,
      infinite: false,
      slide: '.gallery-image-set',
      appendDots: '.dots-wrapper',
      prevArrow:
        '<svg class="svg-arrow-left"><use xlink:href="#svg-arrow-left"></use></svg>',
      nextArrow:
        '<svg class="svg-arrow-right"><use xlink:href="#svg-arrow-right"></use></svg>'
    };
    Palette.Gallery.init('.gallery-image-sets', options);
  });
})(jQuery, window, document);
