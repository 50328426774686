(function($, Choices, window, document) {

  var ContactForm = (function() {

    function Chooser(elem) {
      var options = {
        shouldSort: false,
        searchEnabled: false,
        itemSelectText: ''
      };

      new Choices(elem, options);

      elem.classList.remove('disabled');
    }

    function DateTimeFieldGroups() {
      // console.log('test');
      this.groupSelector = '.contact-form .date-time-field-group.hidden';
      this.expandLink = document.querySelector('.contact-form .expand-link');

      // this.showExpandLinkUnlessLast = function() {
      //   if (document.querySelector(this.groupSelector) && this.expandLink) {
      //     this.expandLink.classList.remove('hidden');
      //   }
      // };
      //
      // this.hideExpandLinkIfLast = function(numHidden) {
      //   if (numHidden === 0) {
      //     this.expandLink.classList.add('hidden');
      //   }
      // };

      this.toggleExpandLink = function(numHidden) {
        var el = this.expandLink;
        var isHidden = el.classList.contains('hidden');
        if (numHidden === 0 && !isHidden) {
          el.classList.add('hidden');
        } else {
          el.classList.remove('hidden');
        }
      }

      this.init = function() {
        if (!document.querySelector('.consultation.fragment .contact-form')) {
          return;
        }

        var groups = this;
        // console.log(groups);
        var closeLinks =
          document.querySelectorAll('.contact-form .close-link');

        if (closeLinks && closeLinks.length) {
          closeLinks.forEach(function(link) {
            link.addEventListener('click', function() {
              var fieldGroup = this.parentNode;
              if (fieldGroup) {
                fieldGroup.classList.add('hidden');
                var inputs = fieldGroup.querySelectorAll('input, select');
                if (inputs && inputs.length) {
                  inputs.forEach(function(input) {
                    input.disabled = true;
                  });
                }
              }
              var numHiddenDateTimeFields = document.querySelectorAll('.consultation .field-group.hidden').length;
              groups.toggleExpandLink(numHiddenDateTimeFields);
            });
          });
        }

        if (this.expandLink) {
          this.expandLink.addEventListener('click', function() {
            var nextGroup = document.querySelector(groups.groupSelector);
            if (nextGroup) {
              nextGroup.classList.remove('hidden');
              var disabledInputs = nextGroup.querySelectorAll('[disabled]');
              if (disabledInputs && disabledInputs.length) {
                disabledInputs.forEach(function(input) {
                  input.disabled = false;
                });
              }
              var timeInput = nextGroup.querySelector('select.disabled');
              if (timeInput) {
                new Chooser(timeInput);
              }
            }
            var numHiddenDateTimeFields = document.querySelectorAll('.consultation .field-group.hidden').length;
            groups.toggleExpandLink(numHiddenDateTimeFields);
          });
        }
      };

      this.init();
    }

    return {
      init: function() {

        // Prevent flash of unstyled select inputs from choices.js
        var consultationForms = [].slice.call(document.querySelectorAll('.consultation .contact-form'));
        consultationForms.forEach(function(form) {
          form.style.visibility = 'visible';
        });

        $('.contact-form-date').pikaday({ firstDay: 0 });

        var firstTimePicker = document.getElementById('contact_time_0');
        var locationPicker = document.querySelector('select#contact-location');

        if (firstTimePicker) {
          new Chooser(firstTimePicker);
        }

        if (locationPicker) {
          new Chooser(locationPicker);
        }

        new DateTimeFieldGroups();
      }
    }

  })();

  document.addEventListener('DOMContentLoaded', function() {
    window.Palette.ContactForm = ContactForm;
    Palette.ContactForm.init();
  });

})(jQuery, Choices, window, document);
