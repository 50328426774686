((d) => {

  function ResponsiveTables() {
    const tables = [...document.querySelectorAll('#layout table')];
    tables.forEach((table) => {
      table.style.visibility = 'visible';
      table.classList.add('responsive-table');
      const headers  = [...table.querySelectorAll('thead th')];
      const headings = headers.map((header) => header.textContent);
      headings.forEach((heading, index) => {
        const tableCells = [...table.querySelectorAll(`tr > td:nth-child(${ index + 1 })`)];
        tableCells.forEach(cell => cell.setAttribute('data-th', heading));
      });
    });
  }

  d.addEventListener('DOMContentLoaded', function() {
    ResponsiveTables();
  });

})(document);
