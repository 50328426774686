(function($, MediaElementPlayer, window, document) {
  var Video = (function() {
    var _setup = function($video, videoType) {
      // var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      // var MEP_Mode = isFirefox ? 'auto_plugin' : 'auto';
      new MediaElementPlayer($video, {
        type: videoType,
        mode: 'auto',
        startVolume: 0.8,
        AndroidUseNativeControls: true,
        iPhoneUseNativeControls: true,
        iPadUseNativeControls: true,
        pluginPath: '//d4xmq39929kw8.cloudfront.net/mediaelement-2.14/',
        plugins: ['flash'],
        flashName: 'flashmediaelement-cdn.swf'
      });
    };

    var init = function(selector) {
      var $elements = $(selector);

      $elements.each(function() {
        $(this).fitVids();
        var $video = $(this).find('video');

        if (!$video.length) {
          return;
        }

        var type = $video.data('type');

        _setup($video, type);

      });

    };

    return {
      init: init
    };
  })();

  document.addEventListener('DOMContentLoaded', function() {
    window.Palette.Video = Video;
    // Video fragments
    Palette.Video.init('.fragment.video.palette-mediaelement');
    // Video page
    Palette.Video.init('.video-box');
  });
})(jQuery, MediaElementPlayer, window, document);
