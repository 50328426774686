(function($, LazyLoad, LoopingVideo, OfficeTour, initValidation, window, document) {
  var Palette = (function() {
    // Init LazyLoad
    new LazyLoad({
      threshold: 900,
      data_src: 'background',
      show_while_loading: true,
      elements_selector: '.lazy-bg'
    });
    new LazyLoad({
      threshold: 900,
      data_src: 'src',
      show_while_loading: true
    });

    var _navOnScroll = function(distance) {
      if (typeof distance !== 'number' || distance < 0) {
        distance = 100;
      }
      var layout = document.querySelector('#layout');
      var mainNav = document.querySelector('.main-nav');
      document.addEventListener('scroll', function() {
        var scrollPosition =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        if (!layout.classList.contains('overlay-active')) {
          if (scrollPosition > distance) {
            mainNav.classList.add('condensed');
          } else {
            mainNav.classList.remove('condensed');
          }
        }
      });
    };

    var _officeTour = function(selector) {
      var $elements = $(selector);
      if ($elements.length > 0) {
        $elements.addClass('pause');
        var officeTour = new OfficeTour(selector); // eslint-disable-line
        officeTour.enable();
      }
    };

    // var setVisibility = function(selector, value) {
    //   var elements = [].slice.call(document.querySelectorAll(selector));
    //   // console.log('Set visibility on ' + elements.length + ' element(s).');
    //   elements.forEach(function(element) {
    //     element.style.visibility = value;
    //   });
    // };

    return {
      init: function() {
        // setVisibility('.palette-typekit', 'visible');
        // Condense nav based on scroll position
        _navOnScroll(100);
        // Looping videos
        LoopingVideo('video.looping-video');
        // Office tour (from Thesis)
        _officeTour('.office-tour__box');
        // Form validation (from Thesis)
        initValidation('is-form'); // eslint-disable-line
      }
    }

  })();

  window.Palette = Palette || {};
  document.addEventListener('DOMContentLoaded', function() {
    Palette.init();
  });

})(jQuery, LazyLoad, LoopingVideo, OfficeTour, initValidation, window, document);
